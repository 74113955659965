<template>
  <div class="wrapper" v-if="disclaimer">
    <div class="sticky-disclaimer-c" v-if="disclaimer && disclaimer.isSticky">
      <div>{{disclaimer.description}}</div>
    </div>
    <div class="bg" v-if="disclaimer && !disclaimer.isSticky && show">
      <div class="c-modal">
        <div class="title">Disclaimer</div>
        <div class="text">{{disclaimer ? disclaimer.description : ''}}</div>
        <b-button @click="onClick" class="custom-btn">Yes, I understand</b-button>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  import DisclaimerVO from "../../vo/DisclamerVO";

  export default {
    name: 'Disclaimer',
    data() {
      return {
        show: false,
        isSticky: false
      }
    },
    created() {
      this.getDisclaimer().then(data => {
        if (this.disclaimer) {
          this.show = true;
          if (!this.disclaimer.isSticky) {
            const body = document.getElementsByTagName('body')[0];
            body.style.overflow = 'hidden';
          }
        }
      });
    },
    computed: {
      ...mapGetters(["disclaimer"])
    },
    methods: {
      ...mapActions(["getDisclaimer"]),
      onClick() {
        this.show = false;
        localStorage.setItem(DisclaimerVO.SO_NAME, this.disclaimer.strJSON);
        const body = document.getElementsByTagName('body')[0];
        body.style.overflow = 'auto';
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .wrapper
    width 100%;
    height 100%;

  .sticky-disclaimer-c
    background-color #005177
    min-height 80px
    display flex
    justify-content center
    align-items center
    padding 10px

  .sticky-disclaimer-c div
    text-align center
    width 70%
    max-width 1444px
    color #FFFFFF
    font-family "Open Sans"
    font-size 14px
    line-height 19px

  .bg
    position absolute
    width 100%
    height 100%
    top 0
    background-color rgba(0, 0, 0, 0.2)
    display flex
    justify-content center
    align-items center
    z-index 99

    .c-modal
      width 629px
      border-radius 10px
      background-color #006491
      box-shadow 0 2px 104px 0 rgba(0, 0, 0, 0.5)
      padding 40px 50px
      display flex
      flex-direction column
      align-items center

      .title
        color #FFFFFF
        font-family Univers57
        font-size 24px
        text-align center
        margin-bottom 36px

      .text
        color #FFFFFF
        font-family "Open Sans"
        font-size 16px
        margin-bottom 30px

      .custom-btn
        height 39px
        width 169px
        border-radius 19.5px
        background-color #FFFFFF
        color #000000
        font-family Univers57
        font-size 16px
        font-weight bold

  @media (max-width: 765px)
    .sticky-disclaimer-c div
      width 100%

</style>
