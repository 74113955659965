<template>
  <div id="app-layout">
		<navbar />
		<disclaimer />
		<router-view></router-view>
		<Footer />
  </div>
</template>

<script>
import Navbar from '../components/Navbar.vue'
import Footer from '../components/Footer.vue'
import Disclaimer from '../components/dashboard/Disclaimer.vue'

export default {
	name: 'MainLayout',
	components: {
		Navbar,
		Footer,
		Disclaimer
	}
}
</script>

<style lang="stylus" scoped>

</style>
