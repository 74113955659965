<template>
  <div :class="['filter-block', mainNavLeft < 72 ? 'm-filter' : '']"
       :style="{left: `${mainNavLeft > 72 ? mainNavLeft - 72 : 20}px`}">
    <b-form-group class="filter" label="Search In">
      <b-form-checkbox-group
          :options="options"
          id="checkbox-group-1"
          name="flavour-1"
          v-model="selected"
      ></b-form-checkbox-group>
    </b-form-group>
  </div>
</template>

<script>
export default {
	name: 'FilterBlock',
	props: {
		mainNavLeft: {
			type: Number
		}
	},
	data () {
		return {
			selected: [],
			options: [
				{ text: 'Course Metadata', value: 'courseMetadata' },
				{ text: 'Notes', value: 'notes' },
				{ text: 'Skills', value: 'skills' },
				{ text: 'Badges', value: 'badges' },
				{ text: 'Tags', value: 'tags' }
			]
		}
	},
	methods: {
		resetFilters() {
			this.selected = [];
		},
		getSearchLink (u) {
			let snippet = '"'
			if (this.selected.indexOf('courseMetadata') !== -1) {
				snippet += 'courseName,courseOverview,courseDescription,moduleName,certificationName,certificationOverview,certificationDescription,jobAidName,jobAidDescription,lpName,lpDescription,lpOverview,'
			}
			if (this.selected.indexOf('notes') !== -1) {
				snippet += 'note,'
			}
			if (this.selected.indexOf('skills') !== -1) {
				snippet += 'skillName,skillDescription,'
			}
			if (this.selected.indexOf('badges') !== -1) {
				snippet += 'badgeName,'
			}
			if (this.selected.indexOf('tags') !== -1) {
				snippet += ' courseTag, moduleTag, jobAidTag, lpTag, certificationTag,'
			}
			if (this.courseMetadata || this.notes || this.skills || this.badges || this.tags) {
				snippet += 'discussion'
			}
			snippet += '"'
			return snippet
		}
	}
}
</script>

<style lang="stylus" scoped>
  .filter-block
    width 190px
    padding 30px 25px 10px
    position absolute
    background-color #0076AC
    top 50px
    z-index 2
    box-shadow 0 5px 14px 0 rgba(0, 0, 0, 0.2)
    border-radius 8px

    .filter
      color #FFFFFF
      font-family "Open Sans"
      font-size 12px
      font-weight 400

    &::before
      content ""
      position absolute
      border-style solid
      border-width 0 8px 10px
      display block
      width 0
      z-index 1
      top -8px
      height 0
      right 0
      left 0
      margin 0 auto
      border-color #0076AC transparent

    &.m-filter
      &::before
        right auto
        left 15px
        margin 0 auto
</style>
