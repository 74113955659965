<script>
import { mapActions, mapGetters } from 'vuex';
import { getMessage } from '../../../helpers';

export default {
	name: 'NotificationModal',
	props: {
		bellNavLeft: {
			type: Number
		}
	},
	computed: {
		...mapGetters(['primeAuth', 'basePrimeApi']),
		...mapGetters('notifications', ['formattedNotifications']),
		notificationsPageURL () {
			return `${this.basePrimeApi}/app/learner?b_qp_custom=false&i_qp_user_id=${this.primeAuth.user_id}&accountId=${this.primeAuth.account_id}#/notifications`;
		}
	},
	methods: {
		...mapActions('notifications', ['readNotifications']),
		getNotificationText (notification) {
			return getMessage(this.basePrimeApi, notification, this.primeAuth);
		}
	}
};
</script>

<template>
  <div class="notification-modal" :style="{left: `${bellNavLeft - 165}px`}">
    <div class="list">
      <div :key="notification.id" class="item" v-for="notification in formattedNotifications">
        <div class="icon">
          <img v-if="notification.read" src="../../../assets/images/notifications/read.svg"/>
          <img v-else src="../../../assets/images/notifications/unread.svg"/>
        </div>
        <div class="data">
          <p v-html="getNotificationText(notification)"/>
          <div class="date">{{ notification.time }}</div>
        </div>
      </div>
    </div>
    <div class="all">
      <a class="see-all-link" :href="notificationsPageURL" target="_blank">
        SEE ALL NOTIFICATIONS
        <img :style="{marginTop: '-2px'}" src='../../../assets/images/arrow-right-white.svg'/>
      </a>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
  .notification-modal
    width 350px
    background-color #0176AC
    border-radius 8px
    position absolute
    z-index 99
    height 450px

    &::before
      content ""
      position absolute
      border-style solid
      border-width 0 10px 12px
      display block
      width 0
      z-index 1
      top -12px
      height 0
      right 0
      left 0
      margin 0 auto
      border-color #0076AC transparent

    .list
      max-height 400px
      overflow-Y scroll

    .item
      padding 15px 20px 10px
      display flex
      flex-direction row
      border 1px solid rgba(255, 255, 255, 0.1)
      cursor default

      .icon
        margin-right 15px

      ::v-deep .data
        color white

        p
          margin 0 0 5px

          a, span
            display inline
            font-weight bold
            color white

          a
            cursor pointer

      .text
        color white
        font-family "Open Sans"
        font-size 14px

      .date
        color white
        opacity 0.5
        font-family "Open Sans"
        font-size 12px

    .all
      position absolute
      bottom 0
      background-color #006491
      border-radius 0 0 10px 10px
      box-shadow 0 5px 14px 0 rgba(0, 0, 0, 0.2)
      height 50px
      width 100%
      display flex

      .see-all-link
        color #FFFFFF
        font-family Univers67B
        font-size 12px
        letter-spacing 1px
        margin auto 0 auto 20px
        cursor pointer

        @media (max-width: 765px)
          .notification-modal
            left auto !important
            right 0

            &::before
              right 94px
              left auto
              margin 0 0
</style>
