<script>
	import {mapActions, mapGetters} from 'vuex';
	import MainNav from './Nav';
	import FilterBlock from './dashboard/components/Filter';
	import NotificationModal from './dashboard/components/NotificationModal';
	
	export default {
		name: 'Navbar',
		data() {
			return {
				searchTxt: '',
				mainNavWidth: 0,
				mainNavLeft: 0,
				bellNavLeft: 0,
				activeSearch: false,
				activeBell: false,
				visibleFilter: false,
				visibleMenu: false,
				notificationsVisible: false,
				event: null,
				updateNotifications: undefined
			};
		},
		components: {
			FilterBlock,
			MainNav,
			NotificationModal
		},
		computed: {
			...mapGetters(['user', 'basePrimeApi', 'primeAuth']),
			...mapGetters('notifications', ['formattedNotifications', 'unreadNotifications']),
			getUserIcon() {
				return this.user.avatarUrl ? this.user.avatarUrl : 'https://s3.us-east-2.amazonaws.com/upload-icon/uploads/icons/png/6620618271548234969-512.png';
			}
		},
		watch: {
			notificationsVisible(newValue) {
				newValue ? this.updateNotification(this.unreadNotifications) : this.getNotifications(this.unreadNotifications);
			}
		},
		created() {
			this.updateNotifications = setInterval(this.getNotifications, 30000);
		},
		mounted() {
			window.document.addEventListener('mouseup', this.onPageDown);
		},
		beforeDestroy() {
			window.document.removeEventListener('mouseup', this.onPageDown);
			clearInterval(this.updateNotifications);
		},
		methods: {
			...mapActions(['doLogout', 'changeRole']),
			...mapActions('notifications', ['getNotifications', 'updateNotification']),
			onPageDown(event) {
				if (this.$refs.searchBtn === event.target || this.$refs.search.$el.contains(event.target)) {
					this.notificationsVisible = false;
				} else if (this.$refs.notificationsBtn === event.target || this.$refs.notifications.$el.contains(event.target)) {
					this.visibleFilter = false;
					this.activeSearch = false;
					this.visibleMenu = false;
				} else {
					this.notificationsVisible = this.visibleFilter = this.activeSearch = this.visibleMenu = false;
				}
			},
			runSearch() {
				if (this.searchTxt) {
					let url = `${this.basePrimeApi}/app/learner?b_qp_custom=false&i_qp_user_id=${this.primeAuth.user_id}&accountId=${this.primeAuth.account_id}#/globalSearch?searchString=${this.searchTxt}&snippetType=`;
					if (this.$refs.searchFilter) {
						url += this.$refs.searchFilter.getSearchLink(this.searchTxt);
					}
					window.open(url, '_self');
				}
			},
			iconClickSearch() {
				if (this.visibleMenu) this.visibleMenu = false;
				this.updatedSizes();
				this.activeSearch = !this.activeSearch;
			},
			iconClickBell() {
				this.updatedSizes();
				this.activeBell = !this.activeBell;
				this.notificationsVisible = !this.notificationsVisible;
			},
			addEventListener() {
				window.addEventListener('resize', () => this.updatedSizes());
			},
			removeEventListener() {
				window.removeEventListener('resize', () => this.updatedSizes());
			},
			updatedSizes() {
				let left = document.getElementById('main-nav').getBoundingClientRect().left;
				left = left > 0 ? left : 20;
				this.mainNavWidth = document.getElementById('bell-icon').getBoundingClientRect().left - left - 10;
				this.mainNavLeft = left;
				
				this.bellNavLeft = document.getElementById('bell-icon').getBoundingClientRect().left;
			},
			menuToggle() {
				this.visibleMenu = !this.visibleMenu;
			}
		}
	};
</script>

<template>
	<div id="header-navbar">
		<b-navbar toggleable="lg" type="dark">
			
			<div class="left-opt">
				<b-navbar-toggle @click="menuToggle" data-not-collapse="true" target=""></b-navbar-toggle>
				
				<b-navbar-brand href="#">
					<img alt="Domino's" class="logo" id="header-logo" src="../assets/images/logo-header.png"/>
				</b-navbar-brand>
			</div>
			
			<main-nav class="desktop" id="main-nav"/>
			
			<div class="right-opt">
				<div class="options">
					<div :class="['search-btn', activeSearch ? 'active' : '']" @click="iconClickSearch"
					     data-not-collapse="true"
					     ref="searchBtn" style="cursor:pointer; pointer-events: all;">
						<img alt="" data-not-collapse="true" id="search-icon" src="../assets/images/search.svg"
						     style="pointer-events: none;">
					</div>
					<div @click="iconClickBell" class="bell" ref="notificationsBtn" style="pointer-events: all;">
						<img alt="" class="bell-icon" id="bell-icon" src="../assets/images/bell.svg"
						     style="pointer-events: none;">
						<div class="notification-count" style="pointer-events: none;" v-if="unreadNotifications.length">
							{{ unreadNotifications.length }}
						</div>
					</div>
				</div>
				<b-dropdown class="user-items-select" right>
					<template #button-content>
						<img :src="getUserIcon" alt="">
						<span class="user-name">{{user.name}}</span>
					</template>
					<b-dropdown-item :active="role == 'Learner'" :key="role" @click="changeRole(role)"
					                 v-for="role in user.roles">{{role}}
					</b-dropdown-item>
					<b-dropdown-divider></b-dropdown-divider>
					<b-dropdown-item @click="doLogout" href="#">Sign Out</b-dropdown-item>
				</b-dropdown>
			</div>
		
		</b-navbar>
		<b-navbar class="mobile" toggleable="lg" type="dark">
			<b-collapse id="nav-collapse" is-nav v-model="visibleMenu">
				<main-nav/>
			</b-collapse>
		</b-navbar>
		<b-collapse class="search-block" id="collapse-search" ref="search" v-model="activeSearch">
			<b-input-group :style="{width: `${mainNavWidth}px`, left: `${mainNavLeft}px`}">
				<b-input-group-prepend @click="visibleFilter = !visibleFilter" is-text style="cursor:pointer;">
					<img src="../assets/images/filter.svg"/>
				</b-input-group-prepend>
				<b-form-input @keydown.enter="runSearch"
				              class="search-input" placeholder="Search" v-model="searchTxt"></b-form-input>
				<b-input-group-append is-text v-show="searchTxt && searchTxt.length">
					<img @click="searchTxt = ''; $refs.searchFilter.resetFilters()" data-collapse="true" height="12"
					     src="../assets/images/close.svg"
					     style="cursor:pointer;" width="12"/>
				</b-input-group-append>
			</b-input-group>
			<b-collapse id="collapse-search-filter" v-model="visibleFilter">
				<filter-block :mainNavLeft="mainNavLeft" ref="searchFilter"/>
			</b-collapse>
		</b-collapse>
		<b-collapse id="collapse-notifications" ref="notifications" v-model="notificationsVisible">
			<notification-modal :bellNavLeft="bellNavLeft"/>
		</b-collapse>
	</div>
</template>

<style lang="stylus" scoped>
	
	#nav-collapse
		padding 10px 0
	
	#header-navbar
		.desktop
			display flex
		
		.mobile
			display none
		
		.navbar
			background-color #1F6391
			padding-left 80px
			padding-right 80px
			
			.right-opt
				display flex
				flex-direction row
				align-items center
				
				.options
					display flex
					flex-direction row
					
					.search-btn
						&.active
							background-color #003851
							border-radius 100%
							display flex
							justify-content center
							align-items center
							padding 2px
							
							#search-icon
								height 18px
								width 18px
					
					.bell
						margin-left 15px
						position relative
						cursor pointer
						
						.notification-count
							position absolute
							height 16px
							width 16px
							color #F9F9F9
							font-family Montserrat
							font-size 10px
							font-weight bold
							letter-spacing 0.54px
							text-align center
							background-color #FD2020
							border-radius 100%
							top 0
							right -5px
	
	.search-block
		width 100%
		background-color #1F6391
		padding 10px
		display flex
		flex-direction row
		margin-top -1px
		position absolute
		z-index 2
		
		.input-group
			position relative
			
			.search-input
				border none
				
			input:focus
				outline none !important
				outline-width 0 !important
				box-shadow none
				-moz-box-shadow none
				-webkit-box-shadow none
			
			.input-group-append
				background-color white
				border-radius 0.25em
				display flex
				align-items center
				justify-content center
				width 50px
				border-top-left-radius 0
				border-bottom-left-radius 0
				
				.input-group-text
					border-radius 100%
					height 20px
					width 20px
					padding 0
					border 0
					display flex
					align-items center
					justify-content center
			
			&::before
				content ""
				position absolute
				border-style solid
				border-width 0 10px 10px
				display block
				width 0
				z-index 1
				top -9px
				height 0
				right 16px
				border-color white transparent
	
	.navbar-toggler
		border none
	
	#collapse-search-filter
		position absolute
	
	#collapse-notifications
		position inherit
	
	@media (max-width: 991px)
		#header-navbar
			.desktop
				display none
			
			.mobile
				display flex
				padding 0 10px !important
				position absolute
				z-index 4
				width 100%
			
			.navbar
				padding-left 10px
				padding-right 10px
	
	@media (max-width: 765px)
		#header-navbar
			.user-name
				display none
</style>
