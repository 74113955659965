<template>
  <div class="navbar-nav">
    <a class="nav-item active" to="/">
      HOME
    </a>
    <a class="nav-item"
       :href="getLink(`catalog/index?myLearning=true&selectedListableCatalogIds=%5B%22${catalogId}%22%2C%22${requiredCatalogId}%22%5D&selectedSortOption=dueDate`)">
      MY LEARNING
    </a>
    
    <a class="nav-item"
       :href="getLink(`catalog/index?selectedListableCatalogIds=%5B%22${catalogId}%22%2C%22${requiredCatalogId}%22%5D&selectedCategories=%22%22&selectedSortOption=-date&selectedTags=%22%22`)">
      COURSE CATALOG
    </a>
    <a class="nav-item" :href="getLink('skills/')">
      SKILLS
    </a>
    <a class="nav-item" :href="getLink('badges')">
      BADGES
    </a>
    <a v-if="showFranchiseTab" class="nav-item" :href="adminLocation">
      MY FRANCHISE
    </a>
    <a v-else-if="showReportsTab" class="nav-item" :href="adminLocation">
      REPORTS
    </a>
  </div>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name: 'MainNav',
		computed: {
			...mapGetters(['primeAuth', 'basePrimeApi', 'catalogId', 'requiredCatalogId', 'user', 'franchise']),
			isLeaderboard () {
				return this.user && this.user.gamificationEnabled;
			},
			showReportsTab () {
				return this.user.roles.filter(r => r === 'Manager').length ||
					this.franchise.adminAccessGroups.indexOf('reptr') !== -1 ||
					this.franchise.adminAccessGroups.indexOf('supvr') !== -1;
			},
			showFranchiseTab () {
				return this.franchise.role === 'franchisee' ||
					this.franchise.adminAccessGroups.indexOf('admsup') !== -1 ||
					this.franchise.adminAccessGroups.indexOf('customAdmin') !== -1;
			},
			adminLocation () {
				return window.location.origin + '/cms';
			}
		},
		methods: {
			getLink (action) {
				return `${this.basePrimeApi}/app/learner?b_qp_custom=false&i_qp_user_id=${this.primeAuth.user_id}&accountId=${this.primeAuth.account_id}#/${action}`;
			}
		}
	};
</script>

<style lang="stylus" scoped>
  .navbar-nav
    margin auto
    align-items: center;

    .nav-item
      font-family Univers67B, serif
      font-size 12px
      line-height 15px
      letter-spacing 0.86px;
      color #fff !important
      text-align center

      &:not(:last-child)
        margin-right 20px

      &.active
        padding: 7px 20px 6px
        text-align center
        border-radius 20px
        background-color #FFFFFF
        color #000 !important
        cursor default
        margin-top -1px

    @media (max-width: 1100px)
      .nav-item
        &:not(:last-child)
          margin-right 10px

    @media (max-width: 991px)
      padding-left 10px
      padding-right 10px
      .nav-item
        margin 10px !important
</style>
