<template>
	<div id="footer">
		<div class="custom-row">
			<div class="img-cont">
				<img alt="Domino's" class="logo"
				     src="../assets/images/logo-footer.png"/>
			</div>
			<div class="item" @click="gotoLink('https://forms.office.com/Pages/ResponsePage.aspx?id=uNswfA6Am0m41mh3b4vJVEQciQoQALhOqw0g9Rachx9URVJDS0JUM0U5MTVPNkpRWjZUMVJSQjZNTi4u')">
				<div class="element-title">
					SUPPORT
				</div>
				<div class="icons">
					<img alt="icon" class="icon" src="../assets/images/email.svg"/>
				</div>
			</div>
			<div class="item" @click="gotoLink('https://helpx.adobe.com/captivate-prime/user-guide.html')">
				<div class="element-title">
					HELP
				</div>
				<div class="icons">
					<img alt="icon" class="icon" src="../assets/images/help.svg"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Footer',
		methods: {
			gotoLink(l){
				window.open(l, '_blank');
			}
		}
	};
</script>

<style lang="stylus" scoped>
	#footer
		min-height 125px
		display flex
		align-items center
		background-color #006490
		margin-top 40px
		
		.custom-row
			padding 0 30px
			width 100%
			align-items center
			display flex
			
			.img-cont
				width: 100%;
				flex-shrink: 1;
				max-width: 460px;
				text-align: center;
				border-right: 1px solid rgba(255, 255, 255, 0.2);
				
				.logo
					width 163px
					height 37px
			
			
			.item
				display flex
				flex-direction column
				justify-content center
				align-items center
				height 100%
				padding-left 20px
				cursor pointer
				
				
				.element-title
					color #FFFFFF
					font-family Univers67B
					font-size 14px
					letter-spacing 1px
					text-align center
					margin-bottom 15px
				
				&:first-child
					border-right 1px solid rgba(255, 255, 255, 0.2)
				
				//height -webkit-fill-available
				
				.icons
					.icon
						width 50px
						height 50px
						margin 0 5px
						border 1px solid rgba(255, 255, 255, 0.2)
						border-radius 100%
						padding 12px

</style>
